*,
*::before,
*::after {
  box-sizing: border-box;
}

/* ---- Custom Properties ---- */
:root {
  --ff-primary: "Source Sans Pro", sans-serif;
  --ff-secondary: "Source Code Pro", monospace;

  --fw-light: 300;
  --fw-medium: 400;
  --fw-bold: 900;

  --clr-light: #fff;
  --clr-dark: #303030;
  --clr-darkest: #1c1c1c;
  --clr-accent: #225c4e;
  --clr-accent2: #5bf5d1;
  --clr-accent3: #004130;
  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem;
  --fs-nav: 1.125rem;
  --fs-body: 1rem;
  --fs-small: 0.875rem;
  --fs-smallest: 0.75rem;

  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 56, 28, 0.185);

  --max-width: 1100px;
}

@media (min-width: 800px) {
  :root {
    --fs-h1: 4.5rem;
    --fs-h2: 3.75rem;
    --fs-h3: 1.5rem;
    --fs-nav: 1.25rem;
    --fs-body: 1.125rem;
    --fs-small: 1rem;
    --fs-smallest: 0.875rem;
  }
}

/* ------------------------ General Styles ------------------------ */
/*Ensure no one becomes motion sick*/
@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

body {
  background-color: var(--clr-light);
  color: var(--clr-dark);
  margin: 0;
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  line-height: 1.6;
}

section {
  padding: 5em 2em;
}

img {
  display: block;
  max-width: 100%;
}

strong {
  font-weight: var(--fw-bold);
}

:focus {
  outline: 3px solid var(--clr-accent2);
  outline-offset: 3px;
}

/*buttons*/
.btn {
  display: inline-block;
  padding: 0.8em 2.5em;
  background: var(--clr-accent);
  color: var(--clr-light);
  text-decoration: none;
  cursor: pointer;
  font-size: var(--fs-small);
  letter-spacing: 2px;
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  border-radius: 3px;
  transition: all 150ms ease-in-out;
}

.btn:hover {
  transform: scale(1.05);
  background-color: var(--clr-accent3);
}

/* ---- Typography ---- */
h1,
h2,
h3 {
  line-height: 1.1;
  margin: 0;
}

h1 {
  font-size: var(--fs-h1);
}
h2 {
  font-size: var(--fs-h2);
}
h3 {
  font-size: var(--fs-h3);
}

.section__title {
  margin-bottom: 0.25em;
}

.section__title--intro {
  font-weight: var(--fw-light);
}

.section__title--intro strong {
  display: block;
}

.section__subtitle {
  margin: 0;
  font-size: var(--fs-h3);
}

.section__subtitle--intro,
.section__subtitle--about {
  font-family: var(--ff-secondary);
  background: var(--clr-accent);
  padding: 0.3em 1.5em;
  color: var(--clr-light);
  margin-bottom: 1em;
}

.section__subtitle--intro {
  text-transform: uppercase;
  position: relative;
}

.section__subtitle--intro::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--clr-accent);
  animation: typewriter 4s forwards;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}
