/* ---- Footer ---- */
.footer {
  background: var(--clr-darkest);
  color: var(--clr-accent2);
  text-align: center;
  padding: 2.5em 0;
  font-size: var(--fs-h3);
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer__link {
  position: relative;
  font-weight: var(--fw-bold);
  padding-bottom: 0.25em;
}

.footer__link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    var(--clr-accent),
    var(--clr-accent2),
    var(--clr-accent)
  );
  background-color: transparent;
  border-radius: 0 0 200% 200%;
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
}

.footer__link:hover::before {
  transform: scaleX(1);
}

.social-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 1.5em 0 0.5em;
  padding: 0;
}

.social-list__item {
  margin: 0 0.5em;
}

.social-list__link {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

.social-list__link p {
  margin: 0;
}

.social-list__link i {
  transition: transform 150ms ease-in-out;
}

.social-list__link:hover i,
.social-list__link:focus i {
  opacity: 0.7;
  transform: scale(1.2) rotate(5deg);
}
