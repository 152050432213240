/* ---- About me ---- */
.about-me {
  max-width: var(--max-width);
  margin: 0 auto;
}

.about-me__skills {
  margin-top: 2em;
  padding: 1em;
  background-color: var(--clr-light);
  box-shadow: var(--bs);
  z-index: 2;
  height: fit-content;
}

.about-me__skills-title {
  color: var(--clr-accent);
}

.about-me__skills-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  width: 100%;
}

.about-me__skills-item {
  margin: 1em 0.5em 0;
  padding: 0.3em 1em;
  /* font-size: var(--fs-small); */
  background-color: var(--clr-dark);
  border: 1px solid var(--clr-accent2);
  border-radius: 10px;
  text-align: center;
  color: var(--clr-light);
}

@media (min-width: 600px) {
  .about-me {
    display: grid;
    grid-template-columns: 1fr minmax(160px, 26%);
    grid-template-areas:
      "title skills"
      "subtitle skills"
      "text skills";
    grid-column-gap: 2em;
  }

  .section__title--about {
    grid-area: title;
  }

  .section__subtitle--about {
    grid-column: 1 / -1;
    grid-row: 2;
    position: relative;
    left: -1.5em;
    width: calc(100% + 3em);
    padding-right: calc(200px + 3.5em);
  }

  .about-me__body {
    grid-area: text;
  }

  .about-me__skills {
    grid-area: skills;
    margin-top: 1em;
  }
}
