/* ---- Header ---- */
header {
  width: 100%;
  border-bottom: 2px solid var(--clr-accent3);
  box-shadow: 0.25em 0.25em 0.75em rgba(5, 117, 93, 0.35),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.2);
  position: fixed;
  background-color: #004130ee;
  z-index: 3;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  max-width: var(--max-width);
  margin: 0 auto;
}

.logo {
  transition: transform 150ms ease-in-out;
}

.logo:hover {
  transform: scale(1.05);
}

.logo__img {
  color: var(--clr-light);
  font-size: 1.75rem;
  transition: transform 150ms ease-in-out;
  margin-top: 5px;
}

.logo__img:focus,
.logo__img:hover {
  transform: scale(1.1);
}

.nav {
  position: fixed;
  background: var(--clr-dark);
  color: var(--clr-light);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  transform: translateX(100%);
  transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
}

.nav__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav__link {
  color: inherit;
  font-weight: var(--fw-bold);
  font-size: var(--fs-h2);
  text-decoration: none;
  letter-spacing: 1px;
}

.nav__link:hover {
  color: var(--clr-accent2);
}

.nav-toggle {
  padding: 1em 0.5em;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.nav-open .nav {
  transform: translateX(0);
}

.nav-open .nav-toggle {
  position: fixed;
  right: 1em;
}

.nav-open .hamburger {
  transform: rotate(0.625turn);
  background: var(--clr-accent2);
}

.nav-open .hamburger::before {
  transform: rotate(90deg) translateX(-8px);
  background: var(--clr-accent2);
}

.nav-open .hamburger::after {
  opacity: 0;
}

.hamburger {
  display: block;
  position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
  background: var(--clr-light);
  width: 2em;
  height: 3px;
  border-radius: 1em;
  transition: transform 250ms ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
}

.hamburger::before {
  top: 8px;
}

.hamburger::after {
  bottom: 8px;
}

@media (min-width: 600px) {
  .nav-toggle {
    display: none;
  }

  .nav {
    position: static;
    background: inherit;
    transition: none;
    transform: translateX(0);
  }

  .nav__list {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }

  .nav__item {
    transition: transform 150ms ease-in-out;
  }

  .home {
    display: none;
  }

  .nav__link {
    color: inherit;
    font-weight: var(--fw-medium);
    font-size: var(--fs-nav);
    margin-left: 0.5em;
    padding: 0.25em 1em;
    border-radius: 3px;
  }

  .nav__link:hover,
  .nav__link:focus,
  .logo__img:hover,
  .logo__img:focus {
    color: var(--clr-light);
  }

  .nav__item:hover {
    transform: scale(1.05);
  }
}

@media (min-width: 800px) {
  .logo__img {
    color: var(--clr-light);
    font-size: 2rem;
    transition: transform 150ms ease-in-out;
  }
}
