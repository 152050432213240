/* ---- My Work ---- */
.my-work {
  background-color: var(--clr-dark);
  text-align: center;
  color: var(--clr-light);
}

.section__subtitle--my-work {
  color: var(--clr-accent2);
  font-weight: var(--fw-bold);
  margin-bottom: 2em;
}

.portfolio {
  max-width: var(--max-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: auto;
  gap: 0.5em;
}

.portfolio__item {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  background: var(--clr-darkest);
  color: var(--clr-light);
  border: solid 2px var(--clr-accent);
  text-decoration: none;
}

.portfolio__item-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.5em;
  margin: 1em 0;
  order: 2;
}

.portfolio__item-title {
  font-size: var(--fs-h3);
  letter-spacing: 1px;
}

.portfolio__item-description {
  flex-grow: 1;
}

.links .portfolio__item-btn {
  font-size: var(--fs-smallest);
  padding: 0.5em 1em;
  text-transform: capitalize;
  font-weight: var(--fw-medium);
}

.portfolio__item-btn + .portfolio__item-btn {
  margin-left: 1em;
}

.portfolio__img--anchor {
  width: 100%;
  height: 100%;
  max-height: 225px;
  order: 1;
  background-color: var(--clr-light);
  overflow: hidden;
}

.portfolio__img {
  width: 100%;
  height: 225px;
  object-fit: cover;
  object-position: center top;
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1), opacity 150ms linear;
}

.portfolio__img:hover,
.portfolio__item:focus .portfolio__img,
.portfolio__item-text:hover + a .portfolio__img {
  transform: scale(1.1);
  opacity: 0.85;
}

@media (min-width: 400px) {
  .portfolio {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
