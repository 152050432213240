/* ---- Services ---- */
.my-services {
  background-color: #3f3f3f;
  background-image: url(./../images/ADK.jpg);
  background-size: cover;
  background-blend-mode: multiply;
  color: var(--clr-light);
  text-align: center;
}

.section__title--services {
  color: var(--clr-accent2);
  position: relative;
}

.section__title--services::after {
  content: "";
  display: block;
  width: 3em;
  height: 1px;
  margin: 0.5em auto 1em;
  background: var(--clr-light);
  opacity: 0.25;
}

.services {
  margin-bottom: 4em;
}

.service {
  max-width: 600px;
  margin: 0 auto 2em;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.14);
  transition: background-color 250ms ease-in-out;
}

.service:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (min-width: 800px) {
  .services {
    max-width: var(--max-width);
    margin: 0 auto 2em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 5fr 1fr;
    grid-template-areas:
      "... two ....."
      "one two three"
      "one ... three";
    column-gap: 2em;
  }

  .service-1 {
    grid-area: one;
  }

  .service-2 {
    grid-area: two;
  }

  .service-3 {
    grid-area: three;
  }
}
